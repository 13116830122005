
import { defineComponent } from "vue";
import { ApiService } from "table";
import TablesAndActions from "@/components/smart/TablesAndActions/TablesAndActions.vue";

export default defineComponent({
  name: "BrowseDefault",
  components: {
    TablesAndActions,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
    };
  },
  computed: {
    path(): string {
      return (this.$route.query as any).path;
    },

    url(): string {
      return ApiService.getUrl("/api/repo/browse-default?", {
        path: this.path,
      });
    },
  },
  async mounted() {},
  methods: {},
});
